<template>
  <div class='page post'>
    <div class='banner'>
      <img src='https://cdn.s777.club/web/about-banner.png'/>
      <h1>Air Combat 1942</h1>
    </div>
    <div class='content'>
      
      <p>Air Combat 1942 is a game in the hunting and rewards genre. In other words, this game has the same way of participating as <a href='https://s777.club'>fish table games</a>. Players will use weapons purchased with real money, aim at targets and win bonuses. The more you participate, the higher the bonus players receive.</p>

      <p>Let's follow the article below to know more about Air Combat 1942!</p>

      <img src='https://cdn.s777.club/web/blog/Air-Combat-1942.jpg' style='margin-bottom:20px;' class='img-fluid'/>

      <h2>What Is Air Combat 1942?</h2>

      <p>It can be said that Air Combat 1942 is the first game belonging to, with high similarity to the fish table games product. Players when participating will have 3 options corresponding to 3 levels. Each tier has different bets, specifically as follows:</p>

      <ul>
        <li>Gold: $1.00 - $10.00 Green fighter, the player who reaches the maximum score will receive a yellow medal with a red star in the middle.</li>
        <li>Silver: $0.10 - $1.00 The fighter is blue, the player when reaching the maximum bonus, the highest peak will receive a yellow medal with a blue star in the middle.</li>
        <li>Bronze: $0.01 - $0.10 At this level the player will own a white plane. This is the easiest rank and players who want to pass will have to receive the green star medal.</li>
      </ul>

      <h2>The Symbols In Air Combat 1942</h2>

      <ul>
        <li>Boss Battle: When entering Boss Battle time, each Boss on the field can be killed continuously until the end of the battle time and the kill reward multiple is 88x - 388x.</li>
        <li>Nuclear bomb: Wins of this item type will cause a ranged attack to kill a certain number of enemies of the same type around.</li>
        <li>Free Shooting: Wins of this item type will get free shooting for a certain period of time. This function will be automatically canceled when you leave the game or change rooms.</li>
      </ul>

      <h2>Paytable In Air Combat 1942</h2>

      <table class='table table-bordered'>
        <tr><th>Symbols</th><th>Multiplier</th></tr>
        <tr>
          <td><img src='https://cdn.s777.club/web/blog/post1-paytable1.jpg' class='img-fluid rounded'/></td>
          <td>x60</td>
        </tr>
        <tr>
          <td><img src='https://cdn.s777.club/web/blog/post1-paytable3.jpg' class='img-fluid rounded'/></td>
          <td>x50</td>
        </tr>
        <tr>
          <td><img src='https://cdn.s777.club/web/blog/post1-paytable5.jpg' class='img-fluid rounded'/></td>
          <td>x40</td>
        </tr>
        <tr>
          <td><img src='https://cdn.s777.club/web/blog/post1-paytable7.jpg' class='img-fluid rounded'/></td>
          <td>x35</td>
        </tr>
        <tr>
          <td><img src='https://cdn.s777.club/web/blog/post1-paytable2.jpg' class='img-fluid rounded'/></td>
          <td>x30</td>
        </tr>
        <tr>
          <td><img src='https://cdn.s777.club/web/blog/post1-paytable4.jpg' class='img-fluid rounded'/></td>
          <td>x20</td>
        </tr>
        <tr>
          <td><img src='https://cdn.s777.club/web/blog/post1-paytable6.jpg' class='img-fluid rounded'/></td>
          <td>x18</td>
        </tr>
        <tr>
          <td><img src='https://cdn.s777.club/web/blog/post1-paytable8.jpg' class='img-fluid rounded'/></td>
          <td>x15</td>
        </tr>
        <tr>
          <td><img src='https://cdn.s777.club/web/blog/post1-paytable9.jpg' class='img-fluid rounded'/></td>
          <td>x12</td>
        </tr>
        <tr>
          <td><img src='https://cdn.s777.club/web/blog/post1-paytable11.jpg' class='img-fluid rounded'/></td>
          <td>x10</td>
        </tr>
        <tr>
          <td><img src='https://cdn.s777.club/web/blog/post1-paytable13.jpg' class='img-fluid rounded'/></td>
          <td>x9</td>
        </tr>
        <tr>
          <td><img src='https://cdn.s777.club/web/blog/post1-paytable15.jpg' class='img-fluid rounded'/></td>
          <td>x8</td>
        </tr>
        <tr>
          <td><img src='https://cdn.s777.club/web/blog/post1-paytable10.jpg' class='img-fluid rounded'/></td>
          <td>x7</td>
        </tr>
        <tr>
          <td><img src='https://cdn.s777.club/web/blog/post1-paytable12.jpg' class='img-fluid rounded'/></td>
          <td>x6</td>
        </tr>
        <tr>
          <td><img src='https://cdn.s777.club/web/blog/post1-paytable14.jpg' class='img-fluid rounded'/></td>
          <td>x5</td>
        </tr>
        <tr>
          <td><img src='https://cdn.s777.club/web/blog/post1-paytable16.jpg' class='img-fluid rounded'/></td>
          <td>x5</td>
        </tr>
        <tr>
          <td><img src='https://cdn.s777.club/web/blog/post1-paytable17.jpg' class='img-fluid rounded'/></td>
          <td>x4</td>
        </tr>
        <tr>
          <td><img src='https://cdn.s777.club/web/blog/post1-paytable19.jpg' class='img-fluid rounded'/></td>
          <td>x3</td>
        </tr>
        <tr>
          <td><img src='https://cdn.s777.club/web/blog/post1-paytable20.jpg' class='img-fluid rounded'/></td>
          <td>x3</td>
        </tr>
        <tr>
          <td><img src='https://cdn.s777.club/web/blog/post1-paytable21.jpg' class='img-fluid rounded'/></td>
          <td>x2</td>
        </tr>
        <tr>
          <td><img src='https://cdn.s777.club/web/blog/post1-paytable18.jpg' class='img-fluid rounded'/></td>
          <td>x2</td>
        </tr>
      </table>

      <h2>Conclusion</h2>

      <p>At first, players when participating in Air Combat 1942 will comment that this game has simple graphics, not very special in terms of images. However, this design is intended to remind players of childhood games, enjoy entertainment and remember the old days. Besides, each gun in the game is really detailed, the lines are realistic with the real gun. Players feel like a real war when participating in <a href='https://s777.club/games/fish-tables'>Air Combat 1942 at the S777.club</a>!</p>

    </div>
  </div>
</template>

<script>
export default {
  name: 'Post1'
}
</script>
